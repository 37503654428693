import React, { useContext, useEffect, useState } from 'react';
import Alert from 'react-popup-alert';
import FeesCategories from '../FeesCategories';
import CollectFee from '../CollectFee';
import { FirebaseContext } from '../../../../../../context/FirebaseContext';
import { useMutation, useQuery } from 'react-query';
import { collectFee, getFeeDueList } from '../../feesManagementServices';
import LoadingAnimation from '../../../../../../components/LoadingAnimation';
import FeeStructure from '../../../../../../CollegeApp/Accounts/FeeStructure';
import Button from '../../../../../../NecttosComp/Button/Button';
import Input from '../../../../../../NecttosComp/Input/Input';
import { Body, Main } from '../../../../../../NecttosComp/Layout/Layout';
import { initializePaymentRazorpay } from '../../../../ApiServices';
import { getAxiosInstance } from '../../../../../../utils/axiosInstance';
import RenderRazorpay from '../../../../../../CollegeApp/Form/Razorpay';

const getCollegeDetails = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};
export default function StudentFeeDetail({ userId, handleClickbackButton, keyDataIsStaff, setStateData, stateData, ...props }) {
	const { collegeId } = useContext(FirebaseContext);
	const [online, setOnline] = React.useState(false);
	const [state, setState] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [unbill, setUnbill] = React.useState(false);
	const [previewComponent, setpreviewComponent] = useState('feeCategory');
	const [feeAmounts, setFeeAmounts] = useState({});
	const [feeReciept, setFeeReciept] = useState({});
	const [date, setDate] = useState('');
	const [totalAmount, setTotalAmount] = useState(0);
	const [feeSummary, setFeeSummary] = useState(props.feeSummary);
	const mutation = useMutation(collectFee);

	const noNeedTotals = true;

	const { data: newdata = [], refetch } = useQuery(['feeDueListdddd', userId, stateData?.isGrandFeeBilling], getFeeDueList(collegeId, noNeedTotals, userId, stateData?.isGrandFeeBilling));
	let data = newdata[0];
	const [admisionNo, setAdmissionNo] = useState();

	useEffect(() => {
		setAdmissionNo(data?.admnNo);
	}, [data?.admnNo]);

	if (keyDataIsStaff) {
		let dataNew = [];
		dataNew = data?.groups?.filter((x) => x.feeType === 'Others');
		console.log({ dataNew });
		if (dataNew?.length >= 0) {
			data.groups = dataNew;
		}
	}

	if (!keyDataIsStaff && ['COL-4E56593776BE-41C3-982A-0B6DC20E44A0', 'COL-F0AF67159D59-4FDF-9915-25CFD728DCA6', 'COL-E67980529419-4EFA-BAF8-ABD89D79284D']?.includes(collegeId)) {
		let dataNew = [];
		dataNew = data?.groups?.filter((x) => x.feeType !== 'Others');
		if (dataNew?.length >= 0) {
			data.groups = dataNew;
		}
	}

	const [alert, setAlert] = React.useState({
		type: 'error',
		text: 'This is a alert message',
		show: false,
	});

	const onCloseAlert = () => {
		setAlert({
			type: '',
			text: '',
			show: false,
		});
	};
	const [stateConst, setStateConst] = useState({ list: { dues: [] } });

	let [items, setItems] = React.useState({});
	const [razorpay, setRazorpay] = useState({ displayRazorpay: false });

	const options = data?.groups?.map((fee) => ({
		key: fee?.feeType,
		name: fee?.feeName,
		recieptNo: fee?.recieptNo,
		bankDrops: fee?.bankDrops,
		currentDue: fee?.totalCurrentDue,
		futureDue: fee?.totalAmount - fee?.totalAdvance - fee?.currentTotalAmount,
		dues: fee?.dues?.map((x) => ({ key: x.title, recieptNo: x?.recieptNo, currentDue: x?.balance, dueDate: x?.dueDate })),
	}));

	useEffect(() => {
		if (Object.keys(props.feeSummary).length) {
			setpreviewComponent('paymentSection');
		}
	}, []);

	let sum = feeAmounts;

	let feesToCollect = Object.keys(feeAmounts)?.map((key) => ({
		key: key,
		value: parseInt(feeAmounts[key]),
	}));

	let total = 0;

	feesToCollect?.forEach((item) => {
		if (item.value) {
			total += item.value;
		}
	});

	console.log({ feeAmounts });

	const viewCategory = () => {
		switch (previewComponent) {
			case 'feeCategory':
				return <FeesCategories options={options} feeAmounts={feeAmounts} setFeeAmounts={setFeeAmounts} feeReciept={feeReciept} setFeeReciept={setFeeReciept} total={total} />;
			case 'paymentSection':
				return <CollectFee feeSummary={feeSummary} setTotalAmount={setTotalAmount} />;
			default:
				return <FeesCategories options={options} feeAmounts={feeAmounts} setFeeAmounts={setFeeAmounts} feeReciept={feeReciept} setFeeReciept={setFeeReciept} />;
		}
	};

	const backButtonClick = () => {
		if (previewComponent === 'feeCategory') handleClickbackButton();
		else if (Object.keys(props.feeSummary).length) handleClickbackButton();
		else {
			setFeeSummary({});
			setpreviewComponent('feeCategory');
		}
	};
	const submitFeeCategory = () => {
		let totalFee = feeSummary;
		Object.entries(feeAmounts)?.forEach(([key, value]) => {
			if (key === 'TuitionFee' || key === 'ExamFee' || key === 'Scholarship') {
				const feeCategory = data?.groups?.find((x) => x.feeType === key);
				let paymentAmount = value;
				console.log({ feeCategory });
				feeCategory?.dues?.forEach((due) => {
					if (paymentAmount > 0) {
						let feePaid = due.balance;
						if (paymentAmount >= due.balance) {
							paymentAmount -= due.balance;
						} else {
							feePaid = paymentAmount;
							paymentAmount = 0;
						}
						totalFee = {
							...totalFee,
							[key]: [
								...(totalFee[key] || []),
								{
									dueId: due._id,
									title: due.title,
									fee: feePaid,
									scholarshipId: feeCategory?.scholarshipId,
								},
							],
						};
					}
				});
			} else {
				const feeCategory = data?.groups?.find((x) => x.feeType === 'Others');
				const due = feeCategory?.dues?.find((x) => x.title === key);
				totalFee = {
					...totalFee,

					[key]: [
						...(totalFee[key] || []),
						{
							dueId: due?._id,
							title: due?.title,
							fee: value,
						},
					],
				};
			}
		});

		setFeeSummary(totalFee);
		if (Object.keys(totalFee).length) {
			setpreviewComponent('paymentSection');
		}
	};

	const onCollectFee = async () => {
		try {
			setIsLoading(true);
			if (Object.keys(feeSummary).length) {
				const postData = {
					collections: Object.entries(feeSummary)
						?.filter(([_, value]) => value?.some((x) => x?.fee))
						?.map(([key, value]) => ({
							feeType: ['TuitionFee', 'ExamFee', 'Scholarship'].includes(key) ? key : 'Others',
							scholarshipId: value?.[0]?.scholarshipId,
							dues: value?.map((x) => ({
								dueId: x.dueId,
								amount: x.fee,
								title: x.title,
							})),
						})),

					method: online === true ? 'Online' : 'Normal',
					isHideBill: unbill,
					recievedBank: state['collections.recievedBank'],
					accountHolderName: state['collections.accountHolderName'],
					transactionId: state['collections.transactionId'],
					transactionDate: state.date,
					status: 'Success',
					feeReciept,
					date,
				};

				let collectedData = await mutation.mutateAsync({ collegeId, userId: data?.userId, userName: data?.userName, data: postData, setIsLoading, setAlert });

				handleClickbackButton({
					collectedData: collectedData?.collectedArray,
					paymentsArray: collectedData?.paymentsArray,
					userName: data?.userName,
					admnNo: data?.admnNo,
					className: data?.className,
					photoURL: data?.photoURL,
				});
				setIsLoading(false);
			}
		} catch (e) {}
	};

	const feeData = {
		body: {
			collections: Object.entries(feeSummary)
				?.filter(([_, value]) => value?.some((x) => x?.fee))
				?.map(([key, value]) => ({
					feeType: ['TuitionFee', 'ExamFee', 'Scholarship'].includes(key) ? key : 'Others',
					scholarshipId: value?.[0]?.scholarshipId,
					dues: value?.map((x) => ({
						dueId: x.dueId,
						amount: x.fee,
						title: x.title,
					})),
				})),

			method: 'Online',
			isHideBill: unbill,
			recievedBank: 'Razorpay',
			accountHolderName: 'Razorpay',
			transactionId: '',
			transactionDate: state.date,
			feeReciept,
			date,
		},
		params: { collegeId, userId: data?.userId, userName: data?.userName },
	};

	const { data: collegeData } = useQuery([`collegeData`, collegeId], getCollegeDetails);

	const handlePaymentRazorpay = async () => {
		const amount = {
			amount: total,
			currencyCode: 'INR',
			feeData,
		};
		const order = await initializePaymentRazorpay(amount, collegeId, userId);

		setRazorpay((prev) => ({ ...prev, order, displayRazorpay: true }));
	};
	return isLoading ? (
		<LoadingAnimation dark />
	) : (
		<Body style={{ backgroundColor: unbill ? 'red' : online ? '#80dfff' : '#ccccff' }}>
			<div>
				{!alert.show ? (
					<div className='flex w-full h-full'>
						<div className='w-2/5 p-4 flex flex-col mt-5'>
							<div className='flex-grow overflow-auto'>
								<div>{viewCategory()}</div>
							</div>
						</div>
						<div className='w-1/5 p-4 flex flex-col space-y-4 mt-5'>
							<Input width='100%' fieldName='Choose Custom Date' state={date} setState={setDate} type='date' />
							<Input width='100%' fieldName='Change Admission Number' state={admisionNo} setState={setAdmissionNo} type='text' />

							<div className='flex justify-between items-center px-4 py-2 bg-white rounded-lg shadow ms-1' style={{ width: '100%' }}>
								<input type='checkbox' className='h-5 w-5' checked={stateData?.isGrandFeeBilling} onChange={() => setStateData({ ...stateData, isGrandFeeBilling: !stateData?.isGrandFeeBilling })} />
								<span className='text-lg'>Enable Pay E Grantz</span>
							</div>
							<div className='flex justify-between items-center px-4 py-2 bg-white rounded-lg shadow  ms-1' style={{ width: '100%' }}>
								<label className='flex items-center space-x-2'>
									<input type='checkbox' className='h-5 w-5' checked={unbill} onChange={() => setUnbill(!unbill)} />
									<span className='text-lg font-medium'>UnBill</span>
								</label>
								<label className='flex items-center space-x-2'>
									<input type='checkbox' className='h-5 w-5' checked={online} onChange={() => setOnline(!online)} />
									<span className='text-lg font-medium'>Online</span>
								</label>
							</div>

							<div className='flex flex-col space-y-2'>
								<Button
									type='fetch'
									onClick={() => {
										setItems({ ...items, feeStructure: true });
									}}>
									Load Fee
								</Button>
								{razorpay?.displayRazorpay && <RenderRazorpay userId={userId} refech={refetch} collegeId={collegeId} accountId={'feeData'} name={data?.userName} data={data} billAmount={total} amount={razorpay?.order?.amount} currency={razorpay?.order?.currency} orderId={razorpay?.order?.id} keyId={`${collegeData?.paymentGateway?.key_id}`} keySecret={`${collegeData?.paymentGateway?.key_secret}`} handleClickbackButton={handleClickbackButton} />}

								{previewComponent === 'feeCategory' ? (
									<>
										<Button type='update' onClick={submitFeeCategory}>
											Calculate {total}
										</Button>
										<Button
											type='close'
											onClick={() => {
												setStateData({ ...stateData, collegeId: '', admisionNo: '', _id: '', data: [] });
											}}>
											Back
										</Button>
									</>
								) : (
									<>
										<Button type='close' onClick={backButtonClick}>
											Back
										</Button>
										<Button type='update' onClick={onCollectFee}>
											Collect Cash {total}
										</Button>
										<Button type='fetch' onClick={handlePaymentRazorpay}>
											Collect Online {total}
										</Button>
									</>
								)}
							</div>
							{online && (
								<>
									<Input width='100%' fieldName='Recieved Bank' didntShowKey returnKey='collections.recievedBank' state={state} setState={setState} options={data?.bankDrops || ['Bank A/C']} type='drop' />
									<Input width='100%' fieldName='Transaction ID' didntShowKey collection='fees' returnKey='collections.transactionId' state={state} setState={setState} type='text' />
									<Input width='100%' fieldName='Account Holder Name' didntShowKey returnKey='collections.accountHolderName' state={state} setState={setState} type='text' />
									<Input width='100%' fieldName='Transaction Date' returnKey='date' state={state} setState={setState} type='date' />
								</>
							)}
						</div>

						<div className='w-2/5 p-4 flex flex-col items-center mt-5'>
							<h1 className='font-bold text-xl'>{data?.admnNo || 'ertyui'}</h1>

							<img style={{ width: 300, height: 400 }} src={data?.photoURL || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/User_icon-cp.svg/828px-User_icon-cp.svg.png'} className='rounded-full border-4 border-green-500 w-40 h-40 object-cover' alt='User' />

							<h1 className='font-bold text-2xl mt-4'>{data?.userName}</h1>
							<p className='text-lg'>{data?.className}</p>
							<div className='text-center mt-2'>
								<p>
									Status: <span className={`font-semibold ${data?.status === 'active' ? 'text-green-600' : 'text-red-600'}`} />
									{data?.status}
								</p>
								<p>Valet: ${data?.valet}</p>
							</div>
						</div>

						{items.feeStructure && (
							<FeeStructure
								setDataConstValue={setStateConst}
								classData={{ classId: data?.classId, className: data?.className }}
								userId={data?.userId}
								loadFee={true}
								dataConstValue={stateConst}
								onClose={() => {
									refetch();
									setItems({ ...items, feeStructure: false });
								}}
							/>
						)}
					</div>
				) : (
					<Alert header={alert.type} btnText={'Close'} text={alert.text} type={alert.type} show={alert.show} onClosePress={onCloseAlert} pressCloseOnOutsideClick={true} showBorderBottom={true} alertStyles={{ height: 200, width: '60%', margin: '20%', backgroundColor: 'red', borderRadius: 10 }} headerStyles={{ color: 'white', width: '100%', textAlign: 'center' }} textStyles={{ color: 'white', width: '100%', textAlign: 'center', fontSize: 20 }} buttonStyles={{ color: 'white', backgroundColor: 'green', padding: 20, borderRadius: 10, margin: '47%' }} />
				)}
			</div>
		</Body>
	);
}
