import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { getAllMinor, getDepartments, getTeachers } from '../../pages/college/ApiServices';
import moment from 'moment';
import { subjectsComplementory } from '../Form/Component';
import { getCollegeDetails } from '../Form/Register';
import SelectField from '../../NecttosComp/SelectField';

interface invoiceManagementProps {
	onClose: () => void;
}

function SubjectRegistry({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>({});
	const [showForm, setShowForm] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [includedClasses, setIncludedClasses] = useState<any>([]);
	const [typeOfMinor, setType] = useState<string>('All');

	const [excludedClasses, setExcludedClasses] = useState<any>([]);
	const [includedClassesLink, setIncludedClassesLink] = useState<any>([]);
	const [excludedClassesLink, setExcludedClassesLink] = useState<any>([]);
	const [includedPlusTwo, setIncludedPlusTwo] = useState<any>([]);
	const [excludedPlusTwo, setExcludedPlusTwo] = useState<any>([]);
	const [splitup, setSplitup] = useState<any>([{ title: '', inTake: '' }]);
	const [teachers, setTeachers] = useState<any>([{ teacherId: '', wl: '' }]);
	const [timetable, setTimetable] = useState<any>([{ day: '', period: '' }]);

	const { data: tableData = [], refetch } = useQuery('getAllMinor', getAllMinor(collegeId));
	const { data: classes } = useQuery([`edeswefdsrf`, collegeId, addNew?.batchName], getCollegeDetails);
	const { data: departments } = useQuery(['getDepartments', collegeId], getDepartments(collegeId));

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteMinor/' + _id, { params: { collegeId } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postMinor', {
					...addNew,
					includedClasses,
					excludedClasses,
					includedPlusTwo,
					excludedPlusTwo,
					includedClassesLink,
					excludedClassesLink,
					collegeId,
					splitup,
					timetable,
					teachers,
				})
				.then(() => {
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	const [data, setData] = useState([]);
	const getData = async () => {
		const instance = await getAxiosTokenInstance();
		const data = await instance.get('/college/subject/getSubjectByFilter', {
			params: { collegeId },
		});
		setData(data?.data?.finalArray);
	};

	useEffect(() => {
		getData();
	}, []);

	const removeIncluded = (subject: any) => {
		setIncludedClasses(includedClasses.filter((s: any) => s !== subject));
	};

	const handleIncludedChange = (event: any) => {
		const value = event.target.value;
		if (!includedClasses?.includes(value)) {
			setIncludedClasses([...(includedClasses || []), value]);
		}
	};

	const removeExcluded = (subject: any) => {
		setExcludedClasses(excludedClasses.filter((s: any) => s !== subject));
	};

	const handleExcludedChange = (event: any) => {
		const value = event.target.value;
		if (!excludedClasses?.includes(value)) {
			setExcludedClasses([...(excludedClasses || []), value]);
		}
	};

	const removeIncludedPlusTwo = (subject: any) => {
		setIncludedPlusTwo(includedPlusTwo.filter((s: any) => s !== subject));
	};

	const handleIncludedChangePlusTwo = (event: any) => {
		const value = event.target.value;
		if (!includedPlusTwo?.includes(value)) {
			setIncludedPlusTwo([...(includedPlusTwo || []), value]);
		}
	};

	const removeExcludedPlusTwo = (subject: any) => {
		setExcludedPlusTwo(excludedPlusTwo.filter((s: any) => s !== subject));
	};

	const handleExcludedChangePlusTwo = (event: any) => {
		const value = event.target.value;
		if (!excludedPlusTwo?.includes(value)) {
			setExcludedPlusTwo([...(excludedPlusTwo || []), value]);
		}
	};

	const handleAddSplitup = () => {
		setSplitup([...splitup, { title: '', inTake: '' }]);
	};

	const handleAddTeacher = () => {
		setTeachers([...teachers, { teacherId: '', wl: '' }]);
	};
	const handleRemoveTeachers = (index: any) => {
		const newTeacher = teachers.filter((_: any, i: any) => i !== index);
		setTeachers(newTeacher);
	};

	const handleTeacherChange = (index: number, key: string, value: any) => {
		const newTeacher = [...teachers];
		newTeacher[index][key] = value;
		setTeachers(newTeacher);
	};

	const handleTeacherChangeDrop = (index: number, key: string, value: any) => {
		const newTeacher = [...teachers];
		newTeacher[index][key] = value.value;
		setTeachers(newTeacher);
	};

	const handleAddTimeTable = () => {
		setTimetable([...timetable, { day: '', period: '' }]);
	};

	const handleRemoveTimeTable = (index: any) => {
		const newTimeTable = timetable.filter((_: any, i: any) => i !== index);
		setTimetable(newTimeTable);
	};

	const handleTimeTableChange = (index: number, key: string, value: any) => {
		const newTimeTable = [...timetable];
		newTimeTable[index][key] = value;
		setTimetable(newTimeTable);
	};
	const removeTimeTableTeachers = (subject: any, timetableId: number) => {
		const updatedTimetable = timetable.map((item: any, i: any) => {
			if (i === timetableId) {
				return {
					...item,
					teachers: item.teachers.filter((s: any) => s !== subject),
				};
			}
			return item;
		});

		setTimetable(updatedTimetable);
	};

	const handleTimeTableTeachers = (index: number, event: any) => {
		const value = event.target.value;
		const updatedTimetable = timetable.map((item: any, i: any) => {
			if (i === index) {
				return {
					...item,
					teachers: item?.teachers?.includes(value) ? item.teachers : [...(item.teachers ?? []), value],
				};
			}
			return item;
		});
		setTimetable(updatedTimetable);
	};

	const handleRemoveSplitup = (index: any) => {
		const newSplitup = splitup.filter((_: any, i: any) => i !== index);
		setSplitup(newSplitup);
	};

	const handleSplitupChange = (index: number, key: string, value: any) => {
		const newSplitup = [...splitup];
		newSplitup[index][key] = value;
		setSplitup(newSplitup);
	};

	const removeIncludedLink = (subject: any) => {
		setIncludedClassesLink(includedClassesLink.filter((s: any) => s !== subject));
	};

	const handleIncludedChangeLink = (event: any) => {
		const value = event.target.value;
		if (!includedClassesLink?.includes(value)) {
			setIncludedClassesLink([...(includedClassesLink || []), value]);
		}
	};

	const removeExcludedLink = (subject: any) => {
		setExcludedClassesLink(excludedClassesLink.filter((s: any) => s !== subject));
	};

	const handleExcludedChangeLink = (event: any) => {
		const value = event.target.value;
		if (!excludedClassesLink?.includes(value)) {
			setExcludedClassesLink([...(excludedClassesLink || []), value]);
		}
	};

	const { tableItems, requestSort, getIcon } = useSortableData(tableData);
	const { data: teachersArr } = useQuery(['getTeacherss', collegeId], getTeachers(collegeId));

	const classOptions = teachersArr?.map((classItem: { _id: string; name: string }) => ({
		value: classItem._id,
		label: classItem.name,
		_id: classItem._id,
	}));

	console.log(teachers, timetable);

	return (
		<>
			<Main title='Master Subject Management' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Button
							type='save'
							onClick={() => {
								setShowForm(true);
							}}>
							Create New Subject
						</Button>

						<Button
							type='submit'
							onClick={() => {
								setType('All');
							}}>
							Show All
						</Button>
						<Button
							type='fetch'
							onClick={() => {
								setType('DSC_B');
							}}>
							Show DSC_B
						</Button>
						<Button
							type='refresh'
							onClick={() => {
								setType('DSC_C');
							}}>
							Show DSC_C
						</Button>
						<Button
							type='search'
							onClick={() => {
								setType('MDC');
							}}>
							Show MDC
						</Button>
					</div>
					<div className='flex'>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width='100%' innerWidth={['5%', '15%', '8%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={2}>Added On</Th>
									<Th position={2}>Modified On</Th>
									<Th position={1}>Subject</Th>
									<Th position={2} onClick={() => requestSort('type')} icon={getIcon('type')}>
										Type of Subject
									</Th>
									<Th position={1} onClick={() => requestSort('departmentName')} icon={getIcon('departmentName')}>
										Department
									</Th>
									<Th position={1}>Pool Name</Th>
									<Th position={1}>Join Our WhatsApp Group Link</Th>
									<Th position={2}>Semester</Th>
									<Th position={2}>Batch</Th>
									<Th position={2}>Seat Limit</Th>
									<Th position={2}>Basket</Th>
									<Th position={1}>Action</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableItems
									.filter((x: any) => typeOfMinor === 'All' || x.type === typeOfMinor)
									?.map((x: any, i: number) => (
										<Tr>
											<Td index={i} position={0}>
												{i + 1}
											</Td>
											<Td index={i} position={2}>
												{moment(x?.addedOn).format('ddd-DD-MM-YYYY')}
											</Td>
											<Td index={i} position={2}>
												{moment(x?.modifiedOn).format('ddd-DD-MM-YYYY')}
											</Td>

											<Td index={i} position={1}>
												{x?.subjectName}
											</Td>
											<Td index={i} position={2}>
												{x?.type}
											</Td>
											<Td index={i} position={1}>
												{x?.departmentName}
											</Td>
											<Td index={i} position={1}>
												{x?.poolName}
											</Td>
											<Td index={i} position={1}>
												{x?.waLink}
											</Td>

											<Td index={i} position={2}>
												{x?.batchName}
											</Td>
											<Td index={i} position={2}>
												{x?.semester}
											</Td>
											<Td index={i} position={2}>
												{x?.seatLimit}
											</Td>
											<Td index={i} position={2}>
												{x?.group}
											</Td>
											<Td index={i} position={1}>
												<div className='flex'>
													<Button
														type='update'
														onClick={() => {
															setAddNew(x);
															setShowForm(true);
															setIncludedClasses(x?.includedClasses);
															setExcludedClasses(x?.excludedClasses);
															setIncludedPlusTwo(x?.includedPlusTwo);
															setExcludedPlusTwo(x?.excludedPlusTwo);
															setExcludedClassesLink(x.excludedClassesLink);
															setIncludedClassesLink(x.includedClassesLink);
															setTeachers(x.teachers || []);
															setTimetable(x.timetable || []);
															setSplitup(
																x?.splitup || [
																	{ title: 'OPEN', inTake: '' },
																	{ title: 'EZ', inTake: '' },
																	{ title: 'MU', inTake: '' },
																	{ title: 'LC', inTake: '' },
																	{ title: 'OBX', inTake: '' },
																	{ title: 'EWS', inTake: '' },
																	{ title: 'OBH', inTake: '' },
																	{ title: 'SC', inTake: '' },
																	{ title: 'ST', inTake: '' },
																	{ title: 'TLM', inTake: '' },
																	{ title: 'SP', inTake: '' },
																	{ title: 'SSQ', inTake: '' },
																	{ title: 'PWD', inTake: '' },
																	{ title: 'LDWP', inTake: '' },
																],
															);
														}}>
														Edit
													</Button>
													{/* <Button type='delete' onClick={() => handleDelete(x?._id)}>
														Delete
													</Button> */}
												</div>
											</Td>
										</Tr>
									))}
							</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>
			{showForm && (
				<Main width='100%' height='100%'>
					<Header>
						<div className='flex'></div>
						<div className='flex'>
							<Button
								type='save'
								width='200px'
								onClick={() => {
									addNewForm();
								}}>
								Save Changes
							</Button>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setShowForm(false), 500);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body>
						<div className='flex flex-row w-full'>
							<div className='flex flex-col w-1/3 p-1'>
								<Input width='100%' fieldName='Choose course offering Department *' returnKey='departmentId' optionDisplay='name' didntShowKey optionKey='_id' state={addNew} setState={setAddNew} options={departments} type='drop' />
								<Input width='100%' fieldName='Batch Name *' returnKey='batchName' state={addNew} setState={setAddNew} options={['2024-2028', '2024-2026', '2024-2027', '2024-2029']} type='drop' />
								<Input width='100%' fieldName='Semester *' returnKey='semester' state={addNew} setState={setAddNew} options={['Semester 1', 'Semester 2', 'Semester 3', 'Semester 4', 'Semester 5', 'Semester 6', 'Semester 7', 'Semester 8']} type='drop' />
								<Input width='100%' fieldName='Join Our WhatsApp Group Link*' returnKey='waLink' state={addNew} setState={setAddNew} type='text' />
								{['DSC_B', 'DSC_C'].includes(addNew.type) && <Input width='100%' fieldName='Pool Name *' returnKey='poolName' state={addNew} setState={setAddNew} type='text' />}
								<Input width='100%' fieldName='Choose Subject *' returnKey='subjectId' optionDisplay='name' didntShowKey optionKey='_id' state={addNew} setState={setAddNew} options={data} type='drop' />
								<Input width='100%' fieldName='Select Type *' returnKey='type' state={addNew} setState={setAddNew} options={['MDC', 'DSC_B', 'DSC_C']} type='drop' />

								{addNew.type === 'DSC_B' && <Input width='100%' fieldName='Select Relationship Between Classes & Subjects Linked with DSC_C:' returnKey='linkedClasses' state={addNew} setState={setAddNew} options={['Included Classes', 'Excluded Classes', 'All Classes', 'None']} type='drop' />}
								{addNew.type === 'DSC_B' && addNew.linkedClasses !== 'None' && (
									<>
										<Input width='100%' fieldName='Select Linked Subject from DSC_C *' returnKey='linkedSubjectId' optionDisplay='subjectName' didntShowKey optionKey='_id' state={addNew} setState={setAddNew} options={tableData.filter((x: any) => x.type === 'DSC_C')} type='drop' />
										{addNew.linkedClasses === 'Included Classes' && (
											<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
												<div className='flex justify-center'>
													<span className='font-bold text-green-700 fs-4'>Includ Linked Classes</span>
												</div>
												{includedClassesLink?.map((subject: any, index: any) => (
													<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
														<span>{classes?.find((x: any) => x._id === subject).className}</span>
														<button onClick={() => removeIncludedLink(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
															Remove
														</button>
													</div>
												))}
												<div className='mt-4'>
													<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
														Select Includ Linked Classes
													</label>
													<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChangeLink} value=''>
														<option value=''> Select Included Classes...</option>
														{classes?.map((subject: any, index: any) => (
															<option key={index} value={subject._id}>
																{subject.className}
															</option>
														))}
													</select>
												</div>
											</div>
										)}
										{addNew.linkedClasses === 'Excluded Classes' && (
											<div className='space-y-4 bg-orange-100 m-2 p-2 rounded-sm'>
												<div className='flex justify-center'>
													<span className='font-bold text-red-700 fs-4'>Exclud Linked Classes</span>
												</div>
												{excludedClassesLink?.map((subject: any, index: any) => (
													<div key={index} className='flex justify-between items-center bg-red-700  text-white font-extrabold p-2 rounded'>
														<span>{classes?.find((x: any) => x._id === subject).className}</span>
														<button onClick={() => removeExcludedLink(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
															Remove
														</button>
													</div>
												))}
												<div className='mt-4'>
													<label htmlFor='subjects' className='block text-sm font-medium text-red-700 mb-1'>
														Select Exclud Linked Classes
													</label>
													<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleExcludedChangeLink} value=''>
														<option value=''>Select Excluded Classes...</option>
														{classes?.map((subject: any, index: any) => (
															<option key={index} value={subject._id}>
																{subject.className}
															</option>
														))}
													</select>
												</div>
											</div>
										)}
									</>
								)}
								<Input width='100%' fieldName='Seat Limit *' returnKey='seatLimit' state={addNew} setState={setAddNew} type='number' />
								<Input width='100%' fieldName='Basket' returnKey='group' state={addNew} setState={setAddNew} options={['A', 'B', 'C', 'D', 'E']} type='drop' />

								<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-blue-700 fs-4'>Teachers</span>
									</div>
									{teachers?.map((item: any, index: any) => (
										<div key={index} className='flex space-x-2'>
											<SelectField id='claswws' name='class' classNameInset='form-multiselect block w-4/5 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' label='Select Class' options={classOptions} value={classOptions?.find((option: any) => option?._id === item?.teacherId) || null} onChange={(selectedOption: any) => handleTeacherChangeDrop(index, 'teacherId', selectedOption)} placeholder='Select a teacher' />

											<input type='number' className='block w-1/5 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='WL' value={item.wl} onChange={(e) => handleTeacherChange(index, 'wl', e.target.value)} />
											<button onClick={() => handleRemoveTeachers(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												R
											</button>
										</div>
									))}
									<button onClick={handleAddTeacher} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
										Add
									</button>
								</div>
								<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-blue-700 fs-4'>Time Table</span>
									</div>
									{timetable?.map((item: any, index: any) => (
										<div className='w-full space-y-4 bg-blue-400 p-2 rounded-lg'>
											<div key={index} className='flex space-x-2'>
												<select className='form-multiselect block w-2/3 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e) => handleTimeTableChange(index, 'day', e.target.value)} value={item.day}>
													<option value=''>Select Day...</option>
													<option value='Monday'>Monday</option>
													<option value='Tuesday'>Tuesday</option>
													<option value='Wednesday'>Wednesday</option>
													<option value='Thursday'>Thursday</option>
													<option value='Friday'>Friday</option>
												</select>
												<input type='number' className='block w-1/3 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='Period' value={item.period} onChange={(e) => handleTimeTableChange(index, 'period', e.target.value)} />

												<button onClick={() => handleRemoveTimeTable(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													R
												</button>
											</div>
											<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
												<div className='flex justify-center'>
													<span className='font-bold text-green-700 fs-4'>Select Teacher</span>
												</div>
												{item.teachers?.map((subject: any, subIndex: any) => (
													<div key={subIndex} className='flex justify-between items-center bg-green-700 text-white font-extrabold p-2 rounded'>
														{classOptions.find((x: any) => x.value === subject)?.label}
														<button onClick={() => removeTimeTableTeachers(subject, index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
															Remove
														</button>
													</div>
												))}
												<div className='mt-4'>
													<label htmlFor={`subjects-${index}`} className='block text-sm font-medium text-gray-700 mb-1'>
														Select Teachers
													</label>
													<select id={`subjects-${index}`} className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e: any) => handleTimeTableTeachers(index, e)} value=''>
														<option value=''>Select Teachers...</option>
														{teachers?.map((subject: any, subIndex: any) => (
															<option key={subIndex} value={subject.teacherId}>
																{classOptions.find((x: any) => x.value === subject?.teacherId)?.label}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
									))}
									<button onClick={handleAddTimeTable} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
										Add
									</button>
								</div>
							</div>
							<div className='flex flex-col w-1/4 p-1'>
								<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-green-700 fs-4'>Included +2 Subject</span>
									</div>

									{includedPlusTwo?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
											<span>{subject}</span>
											<button onClick={() => removeIncludedPlusTwo(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}
									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
											Select Included Plus Two Subject
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChangePlusTwo} value=''>
											<option value=''> Select Included Plus Two Subject...</option>
											{subjectsComplementory?.map((subject: any, index: any) => (
												<option key={index} value={subject}>
													{subject}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className='space-y-4 bg-orange-100 m-2 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-red-700 fs-4'>Excluded +2 Subject</span>
									</div>
									{excludedPlusTwo?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-red-700  text-white font-extrabold p-2 rounded'>
											<span>{subject}</span>
											<button onClick={() => removeExcludedPlusTwo(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}
									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-red-700 mb-1'>
											Select Excluded Plus Two Subject
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleExcludedChangePlusTwo} value=''>
											<option value=''>Select Excluded Plus Two Subject...</option>
											{subjectsComplementory?.map((subject: any, index: any) => (
												<option key={index} value={subject}>
													{subject}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className='flex flex-col w-1/4 p-1'>
								<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-green-700 fs-4'>Included Classes</span>
									</div>
									{includedClasses?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
											<span>{classes?.find((x: any) => x._id === subject).className}</span>
											<button onClick={() => removeIncluded(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}
									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
											Select Included Classes
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChange} value=''>
											<option value=''> Select Included Classes...</option>
											{classes?.map((subject: any, index: any) => (
												<option key={index} value={subject._id}>
													{subject.className}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className='space-y-4 bg-orange-100 m-2 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-red-700 fs-4'>Excluded Classes</span>
									</div>
									{excludedClasses?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-red-700  text-white font-extrabold p-2 rounded'>
											<span>{classes?.find((x: any) => x._id === subject).className}</span>
											<button onClick={() => removeExcluded(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}

									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-red-700 mb-1'>
											Select Excluded Classes
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleExcludedChange} value=''>
											<option value=''>Select Excluded Classes...</option>
											{classes?.map((subject: any, index: any) => (
												<option key={index} value={subject._id}>
													{subject.className}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className='flex flex-col w-1/4 p-1'>
								<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-blue-700 fs-4'>Reservation Splitup</span>
									</div>
									{splitup?.map((item: any, index: any) => (
										<div key={index} className='flex space-x-2'>
											<select className='form-multiselect block w-1/2 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e) => handleSplitupChange(index, 'title', e.target.value)} value={item.title}>
												<option value=''>Select Title...</option>
												<option value='OPEN'>OPEN</option>
												<option value='EZ'>EZ</option>
												<option value='MU'>MU</option>
												<option value='LC'>LC</option>
												<option value='OBX'>OBX</option>
												<option value='EWS'>EWS</option>
												<option value='OBH'>OBH</option>
												<option value='SC'>SC</option>
												<option value='ST'>ST</option>
												<option value='TLM'>TLM</option>
												<option value='SP'>SP</option>
												<option value='SSQ'>SSQ</option>
												<option value='PWD'>PWD</option>
												<option value='LDWP'>LDWP</option>
											</select>
											<input type='number' className='block w-1/2 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='Intake' value={item.inTake} onChange={(e) => handleSplitupChange(index, 'inTake', e.target.value)} />
											<button onClick={() => handleRemoveSplitup(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												R
											</button>
										</div>
									))}
									<button onClick={handleAddSplitup} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
										Add
									</button>
								</div>
							</div>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default SubjectRegistry;
