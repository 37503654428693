import React, { useContext, useRef, useState } from "react";
import classNames from "./addUserForm.module.scss";
import Select from "antd/lib/select";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useQuery } from "react-query";
import { Field, ErrorMessage, Form, Formik, useFormikContext } from "formik";
import LoadingAnimation from "../LoadingAnimation";
import { ArrowRightOutlined } from "@ant-design/icons";
import UploadMultipleUserCsv from "../UploadMultipleUserCsv";
import Button from "../../NecttosComp/Button/Button";

const { Option } = Select;

const userTypess = [
  { name: "Student", key: "student" },
  { name: "Teacher", key: "teacher" },
  { name: "Staff", key: "staff" },
];

const getDepartmentList = async ({ queryKey }) => {
  const [_, { collegeId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  let resp;
  if (collegeId)
    try {
      resp = await instance.get("/college/classes/getComponent", {
        params: { type: "departments", collegeId },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error) {
      throw new Error(error?.response?.data?.message || error.message || "API Error");
    }
  return resp?.data;
};

const getClassesList = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_, { collegeId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  let resp;
  if (collegeId)
    try {
      resp = await instance.get("/college/classes/getClass", {
        params: { collegeId,needAll:true },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error) {
      throw new Error(error?.response?.data?.message || error.message || "API Error");
    }
  return resp?.data;
};

function AddUserForm({
  toggleRefresh,
  userType,
  setUserType,
  classId,
  setClassId,
  departmentId,
  setDepartmentId,
  name,
  phoneNumber,
  admisionNo,
  setAdmisionNo,
  setPhoneNumber,
  setName,

  id,
  setId,
}) {
  const ref = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadMultipleUser, setMultipleUserUpload] = useState(false);
  const { collegeId } = useContext(FirebaseContext);

  const onAddUser = async (data, actions) => {
    try {
      if (data.fullName) {
        data.type = userType;
        data.classId = classId;
        data.departmentId = departmentId;
        setIsLoading(true);
        const instance = await getAxiosTokenInstance();
        let resp;

        if (id) {
          data.id = id;
          data.phoneNumber = data?.phoneNumber?.replace("+91", "");
          resp = await instance.put("/college/users/updateUser", { ...data });
        } else {
          resp = await instance.post("/college/users/addUser", { ...data });
        }
        if (resp.data.statusCode !== 200) {
          throw new Error(resp.data.message || "API Error");
        }
        toggleRefresh();
        message.success(data?.message || "User Added");
        actions.resetForm();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classNames.addUserForm}>
      <Formik
        initialValues={{
          collegeId,
          type: userType,
          fullName: name,
          phoneNumber: phoneNumber,
          classId: "",
          departmentId: "",
          admisionNo: admisionNo,
        }}
        enableReinitialize
        // validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikActions) => {
          onAddUser(values, formikActions);
          setClassId("");
          setDepartmentId("");
          setName("");
          setPhoneNumber("");
          setAdmisionNo("");
          setId("");
        }}
      >
        {({ resetForm }) => (
          <Form ref={ref} className={classNames.userForm}>
            <SetUserType setUserType={setUserType} userType={userType} />
            <div className={classNames.error}>
              <ErrorMessage name="userType" />
            </div>
            {!uploadMultipleUser && (
              <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                <Field type="text" name="fullName" required />
                <label>Full Name (As per SSLC)</label>
                <div className={classNames.error}>
                  <ErrorMessage name="fullName" />
                </div>
              </div>
            )}
            {uploadMultipleUser && userType && (
              <UploadMultipleUserCsv
                onCancel={() => {
                  resetForm();
                  setMultipleUserUpload(false);
                }}
                userType={userType}
              />
            )}
            {!uploadMultipleUser && (
              <>
                <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                  <Field type="text" maxLength={10} name="phoneNumber" required />
                  <label>Official Mobile Number</label>
                  <div className={classNames.error}>
                    <ErrorMessage name="phoneNumber" />
                  </div>
                </div>
                <SelectClassOrDep classId={classId} setClassId={setClassId} setDepartmentId={setDepartmentId} departmentId={departmentId} userType={userType} collegeId={collegeId} />
              </>
            )}
            {!uploadMultipleUser && (
              <div className={classNames.btns}>
                <div
                  className={classNames.btnClear}
                  onClick={() => {
                    setUserType("");
                    setClassId("");
                    setDepartmentId("");
                    setName("");
                    setPhoneNumber("");
                    setAdmisionNo("");
                    setId("");
                    resetForm();
                  }}
                  // onClick={resetForm}
                >
                  Clear
                </div>
                <div className={classNames.formButtonWidth}>
                  <button type="primary" value="Submit">
                    Submit <ArrowRightOutlined style={{ fontSize: 22, margin: "auto 0 auto 5px" }} />
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <Button
        type="search"
        onClick={() => {
          setMultipleUserUpload(!uploadMultipleUser);
        }}
      >
        {uploadMultipleUser ? "Add Single User" : "Add multiple users"}
      </Button>
      {isLoading && (
        <div className={classNames.loading}>
          <LoadingAnimation />
        </div>
      )}
    </div>
  );
}

function SetUserType({ userType, setUserType }) {
  const { setFieldValue } = useFormikContext();
  return (
    <Select
      showSearch
      value={userType || undefined}
      className={classNames.formFullWidth}
      style={{ overflow: "hidden" }}
      placeholder="Select User Type"
      onChange={(val) => {
        setUserType(val);
        setFieldValue("type", val);
      }}
    >
      {userTypess?.map((type) => (
        <Option key={type.key} value={type.key}>
          {type.name}
        </Option>
      ))}
    </Select>
  );
}

function SelectClassOrDep({ userType, collegeId, classId, departmentId, setClassId, setDepartmentId }) {
  // const { values, setFieldValue } = useFormikContext();
  const departmentQuery = useQuery(["departments", { collegeId }], getDepartmentList); //{ data, status, error, refetch }
  const classQuery = useQuery(["classes", { collegeId }], getClassesList);
  switch (userType) {
    case "student":
      return (
        <>
          <div className={`${classNames.group} ${classNames.formFullWidth}`}>
            <Field type="text" name="admisionNo" placeholder="Ignore: The admission number will be generated automatically" />
            <label>Admission Number</label>
            <div className={classNames.error}>
              <ErrorMessage name="admisionNo" />
            </div>
          </div>
          <Select showSearch value={classId || undefined} placeholder="Select Class" className={classNames.formFullWidth} style={{ overflow: "hidden" }} onChange={(val, op) => setClassId(val)} key="classId">
            {classQuery?.data?.list?.map((classes) => (
              <Option key={classes._id} value={classes._id}>
                {classes.className}
              </Option>
            ))}
          </Select>
          <div className={classNames.error}>
            <ErrorMessage name="classId" />
          </div>
        </>
      );
    case "teacher":
      return (
        <>
          <Select showSearch value={departmentId} defaultValue="" className={classNames.formFullWidth} style={{ overflow: "hidden" }} onChange={(val, op) => setDepartmentId(val)} key="departmentId">
            <Option disabled value="">
              Select Department
            </Option>
            {departmentQuery?.data?.list?.map((dep) => (
              <Option key={dep._id} value={dep._id}>
                {dep.name}
              </Option>
            ))}
          </Select>
          <div className={classNames.error}>
            <ErrorMessage name="departmentId" />
          </div>
        </>
      );
    default:
      return <></>;
  }
}

export default AddUserForm;
