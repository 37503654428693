import React, { useContext, useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import moment from 'moment';
import { FirebaseContext } from '../../context/FirebaseContext';
import Input from '../../NecttosComp/Input/Input';

const UserDetails = ({ isOpen, onClose, modalData, refetch }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { collegeId } = useContext(FirebaseContext);

	const initialFormData = {
		userId: '',
		classId: '',
		name: '',
		dob: '',
		admisionNo: '',
		secondLanguage: '',
		rollNo: '',
		hallTicketNo: '',
		bloodGroup: '',
		category: '',
		admType: '',
		collegeId: '',
		dateOfAdmission: '',
		religion: '',
		caste: '',
		rcMark: '',
		rcGroup: '',
		index: '',
	};

	const [formData, setFormData] = useState(initialFormData);

	const [userProfileData, setUserProfileData] = useState('');
	const [classDetails, setClassDetails] = useState(null);
	const [dropDown, setDropDown] = useState(null);
	const [categoryDropDown, setCategoryDropDown] = useState(null);
	const [religionDropDown, setReligionDropDown] = useState(null);
	const [casteDropDown, setCasteDropDown] = useState(null);

	const getUser = async () => {
		const instance = await getAxiosTokenInstance();
		const { data } = await instance.get('/college/profile/get', {
			params: {
				profileId: modalData?.uid,
			},
		});

		setUserProfileData(data.profileData);
		setFormData({
			...data.profileData,
			dob: moment(new Date(data.profileData.dob)).format('YYYY-MM-DD'),
			dateOfAdmission: moment(new Date(data.profileData.dateOfAdmission)).format('YYYY-MM-DD'),
			classId: data.profileData.collegeRoles.find((role) => role.type === 'student').classId,
			collegeId: data.profileData.collegeId, // Set collegeId from profileData
		});
	};
	const updateUserData = async () => {
		try {
			setIsLoading(true);
			const instance = await getAxiosTokenInstance();

			const { data } = await instance.post('/college/profile/updatedUserProfile', { ...formData });

			message.success(data.message);
			setFormData(initialFormData);
			refetch && refetch();
		} catch (error) {
			message.error('Error updating profile: ' + error.message);
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	const getClassesList = async (collegeId) => {
		try {
			const instance = await getAxiosTokenInstance();
			const resp = await instance.get('/college/classes/getClass', {
				params: { collegeId, needAll: true, classOnly: true },
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			}

			setClassDetails(resp.data);
		} catch (error) {
			console.error('Error fetching classes: ', error?.response?.data?.message || error.message || 'API Error');
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

	useEffect(() => {
		if (modalData.uid) {
			getUser();
		}
	}, [modalData.uid]);

	useEffect(() => {
		if (collegeId) {
			getClassesList(collegeId);
		}
	}, [collegeId]);

	const handleProfileEdit = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const Language = ['Arabic', 'Assamese', 'Bengali', 'Bhasa Melayu', 'Bhutia', 'Bodo', 'English', 'French', 'German', 'Gujarati', 'Hindi', 'Japanese', 'Kannada', 'Kashmiri', 'Latin', 'Lepcha', 'Limboo', 'Malayalam', 'Manipuri', 'Marathi', 'Mizo', 'Nepali', 'Odia', 'Persian', 'Portuguese', 'Punjabi', 'Russian', 'Sanskrit', 'Sanskrit Sahitya', 'Sanskrit Sasthra', 'Sindhi', 'Spanish', 'Syriac', 'Tamil', 'Tangkhul', 'Telugu', 'Tibetan', 'Urdu'];

	const getDropdownData = async () => {
		const instance = await getAxiosTokenInstance();
		const { data } = await instance.get('/dropDown/dropdownList');
		console.log('dropdown', data);
		setDropDown(data.dropdownList[2]);
		// setCategoryDropDown(data.dropdownList[2]?.RELIGION[0][formData.religion + 'RESERVATIONCATEGORY']);
		// setReligionDropDown(data.dropdownList[2]?.RELIGION[0]?.RELIGIONNAME);
		// setCasteDropDown(data.dropdownList[2]?.RELIGION?.[0]?.[formData.religion + 'CAST']?.[0]?.[formData?.category]);
		if (data.statusCode !== 200) {
			throw new Error(data.message || 'API Error');
		}
		let val = data?.dropdownList;
		return val;
	};

	useEffect(() => {
		setCategoryDropDown(dropDown?.RELIGION[0][formData.religion + 'RESERVATIONCATEGORY']);
		setReligionDropDown(dropDown?.RELIGION[0]?.RELIGIONNAME);
		setCasteDropDown(dropDown?.RELIGION?.[0]?.[formData.religion + 'CAST']?.[0]?.[formData?.category]);
	}, [formData.religion]);

	useEffect(() => {
		getDropdownData();
	}, [formData.classId]);

	const [admTypes, setAdminTypes] = useState([]);

	useEffect(() => {
		const classDetail = classDetails && classDetails?.list && classDetails?.list?.find((x) => x._id === formData.classId);

		if (classDetail && classDetail.splitup) {
			const newPsl = classDetail.splitup.map((item) => item.title);
			setAdminTypes(newPsl);
		}
	}, [formData.classId, classDetails]);

	const RC_GROUP = [
		{ title: 'OPEN', inTake: '' },
		{ title: 'EZ', inTake: '' },
		{ title: 'MU', inTake: '' },
		{ title: 'LC', inTake: '' },
		{ title: 'OBX', inTake: '' },
		{ title: 'EWS', inTake: '' },
		{ title: 'OBH', inTake: '' },
		{ title: 'SC', inTake: '' },
		{ title: 'ST', inTake: '' },
		{ title: 'TLM', inTake: '' },
		{ title: 'SP', inTake: '' },
		{ title: 'SSQ', inTake: '' },
		{ title: 'PWD', inTake: '' },
		{ title: 'LDWP', inTake: '' },
	];

	return (
		<Modal width={'70%'} style={{ marginTop: '-3%', overflow: 'scroll' }} title={`Update ${modalData?.name}'s all Data`} visible={isOpen} confirmLoading={isLoading} onCancel={onClose} okText='Update Data' footer={null}>
			<div className='mt-[-3]'>
				<div className='grid grid-cols-2 gap-2'>
					<div className='mb-2'>
						<label htmlFor='name' className='block text-sm font-medium text-gray-700 mb-1'>
							Name:
						</label>
						<input type='text' id='name' name='name' value={formData.name} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='dob' className='block text-sm font-medium text-gray-700 mb-1'>
							Date of Birth:
						</label>
						<input type='date' id='dob' name='dob' value={formData.dob instanceof Date ? moment(formData.dob, ['DD MMMM YYYY', 'DD MM YYYY', 'YYYY-MM-DD', 'D MMMM YYYY']).format('DD-MM-YYYY') : formData.dob} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='admisionNo' className='block text-sm font-medium text-gray-700 mb-1'>
							Admission No:
						</label>
						<input type='text' id='admisionNo' name='admisionNo' value={formData.admisionNo} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='secondLanguage' className='block text-sm font-medium text-gray-700 mb-1'>
							Second Language:
						</label>
						<select id='secondLanguage' name='secondLanguage' value={formData.secondLanguage} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							{Language.map((lang) => (
								<option key={lang} value={lang}>
									{lang}
								</option>
							))}
						</select>
					</div>
					<div className='mb-2'>
						<label htmlFor='classId' className='block text-sm font-medium text-gray-700 mb-1'>
							Class:
						</label>
						<select id='classId' name='classId' value={formData.classId} onChange={(e) => setFormData({ ...formData, classId: e.target.value })} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value='' disabled>
								Select class
							</option>
							{classDetails &&
								classDetails.list &&
								classDetails.list.map((cls) => (
									<option key={cls._id} value={cls.classId}>
										{cls.className}
									</option>
								))}
						</select>
					</div>
					<div className='mb-2'>
						<label htmlFor='rollNo' className='block text-sm font-medium text-gray-700 mb-1'>
							Roll Number:
						</label>
						<input type='text' id='rollNo' name='rollNo' value={formData.rollNo} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='hallTicketNo' className='block text-sm font-medium text-gray-700 mb-1'>
							Hall Ticket No:
						</label>
						<input type='text' id='hallTicketNo' name='hallTicketNo' value={formData.hallTicketNo} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='rcMark' className='block text-sm font-medium text-gray-700 mb-1'>
							RC_MARK:
						</label>
						<input type='text' id='rcMark' name='rcMark' value={formData.rcMark} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='rcGroup' className='block text-sm font-medium text-gray-700 mb-1'>
							RC_GROUP:
						</label>
						<select id='rcGroup' name='rcGroup' value={formData.rcGroup} onChange={handleProfileEdit} className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value={formData.rcGroup} disabled>
								{formData.rcGroup}
							</option>

							{RC_GROUP &&
								RC_GROUP.map((item) => (
									<option key={item.title} value={item.title}>
										{item.title}
									</option>
								))}
						</select>
					</div>
					<div className='mb-2'>
						<label htmlFor='index' className='block text-sm font-medium text-gray-700 mb-1'>
							INDEX:
						</label>
						<input type='text' id='index' name='index' value={formData.index} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>
					<div className='mb-2'>
						<label htmlFor='bloodGroup' className='block text-sm font-medium text-gray-700 mb-1'>
							Blood Group:
						</label>
						<select id='bloodGroup' name='bloodGroup' value={formData.bloodGroup} onChange={handleProfileEdit} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value=''>{formData.bloodGroup}</option>
							<option value='A+'>A+</option>
							<option value='A-'>A-</option>
							<option value='B+'>B+</option>
							<option value='B-'>B-</option>
							<option value='AB+'>AB+</option>
							<option value='AB-'>AB-</option>
							<option value='O+'>O+</option>
							<option value='O-'>O-</option>
						</select>
					</div>
					<div className='mb-2'>
						<label htmlFor='religion' className='block text-sm font-medium text-gray-700 mb-1'>
							Religion:
						</label>
						<select id='religion' name='religion' value={formData.religion} onChange={handleProfileEdit} className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value={formData.religion} disabled>
								{formData.religion}
							</option>
							{religionDropDown &&
								religionDropDown.map((religion) => (
									<option key={religion} value={religion}>
										{religion}
									</option>
								))}
						</select>
					</div>

					<div className='mb-2'>
						<label htmlFor='admType' className='block text-sm font-medium text-gray-700 mb-1'>
							Admission Quota:
						</label>
						<select id='admType' name='admType' value={formData.admType} onChange={handleProfileEdit} className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value=''>{formData?.admType}</option>
							{admTypes &&
								admTypes.map((category) => (
									<option key={category} value={category}>
										{category}
									</option>
								))}
						</select>
					</div>
					<div className='mb-2'>
						<label htmlFor='category' className='block text-sm font-medium text-gray-700 mb-1'>
							Category:
						</label>
						<select id='category' name='category' value={formData.category} onChange={handleProfileEdit} className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value={formData.category} disabled>
								{formData.category}
							</option>
							{categoryDropDown &&
								categoryDropDown.map((category) => (
									<option key={category} value={category}>
										{category}
									</option>
								))}
						</select>
					</div>
					<div className='mb-2'>
						<label htmlFor='dateOfAdmission' className='block text-sm font-medium text-gray-700 mb-1'>
							Date of Admission:
						</label>
						<input type='date' id='dateOfAdmission' name='dateOfAdmission' value={formData.dateOfAdmission} onChange={handleProfileEdit} className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
					</div>

					<div className='mb-2'>
						<label htmlFor='caste' className='block text-sm font-medium text-gray-700 mb-1'>
							Caste:
						</label>
						<select id='caste' name='caste' value={formData.caste} onChange={handleProfileEdit} className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<option value={formData.caste} disabled>
								{formData.caste}
							</option>

							{casteDropDown &&
								casteDropDown.map((caste) => (
									<option key={caste} value={caste}>
										{caste}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className='flex justify-end mt-4'>
					<button onClick={updateUserData} type='button' className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
						Update
					</button>
					<button onClick={onClose} className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
						Close
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default UserDetails;
