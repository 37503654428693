import React, { useContext, useEffect, useRef, useState } from "react";
import "./input.css";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuid } from "uuid";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useQuery } from "react-query";

interface OptionType {
  [key: string]: any; // Adjust this based on your actual option structure
}

const getFieldDropDowns =
  ({ collegeId, search, collection, key }: any) =>
  async () => {
    if (collection) {
      const instance = await getAxiosTokenInstance();
      try {
        const response = await instance.get("/college/action/getFieldDropDowns", {
          params: { collegeId, search, collection, key },
        });
        if (response.data.statusCode === 400) {
          throw new Error(response.data.message || "API Error");
        }
        return response?.data?.list;
      } catch (error) {
        const message = "API Error";
        throw new Error(message);
      }
    }
    return []; // Return empty array if the condition is not met
  };

interface InputProp {
  type: "text" | "number" | "date" | "drop" | "email" | "file" | "time" | "month" | "checkbox";
  setState?: (newState: any) => void;
  state?: any; // You can replace 'any' with a more specific type if you know the structure of your state
  returnKey?: string;
  fieldName: string;
  options?: OptionType[] | any[]; // Assuming options is an array of objects
  inputValue?: string;
  optionDisplay?: string;
  optionKey?: string;
  width?: string;
  maxLength?: number;
  valueShowKey?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (e: { name?: string; value: string }) => void;
  didntShowKey?: boolean;
  needCheck?: boolean;
  className?: string;
  isArray?: boolean;
  customeTypable?: boolean;
  indexVal?: number;
  arrayName?: string;
  optionsAll?: OptionType[] | any[];
  aditionalInfo?: string;
  min?: number;
  max?: number;
  collectionName?: string;
  distinctKey?: string;
  typable?: boolean;
}
function Input({ collectionName, typable, distinctKey, type, setState, state, isArray, needCheck, aditionalInfo, customeTypable = false, indexVal, arrayName, min, max, valueShowKey, className, returnKey, fieldName, options, optionsAll, optionDisplay, maxLength, optionKey, didntShowKey, width = "10vw", onChange, required = false, disabled = false }: InputProp) {
  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>();
  const { collegeId } = useContext(FirebaseContext);
  const [selected, setSelected] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputDropValue, setInputDropValue] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef: any = useRef(null);
  const style = {
    width,
  };
  useEffect(() => {
    if (optionKey && optionDisplay) {
      const dataset = options?.find((option) => option?.[optionKey] === state);
      setSelected(dataset?.[optionDisplay] ?? null);
    }
  }, [optionKey, optionDisplay, state, options, setSelected]);

  useEffect(() => {
    if (arrayName && indexVal !== undefined && returnKey && state) {
      const value = state?.[arrayName]?.[indexVal]?.[returnKey];
      if (value !== undefined) {
        setSelected(value);
      }
    }
  }, [state, arrayName, indexVal, returnKey]);

  useEffect(() => {
    if (returnKey && optionKey && optionDisplay && state?.[returnKey]) {
      const dataset = (optionsAll || options)?.find((option) => option?.[optionKey] === state?.[returnKey]);

      setSelected(dataset?.[optionDisplay] ?? null);
    }
  }, [returnKey, optionKey, optionDisplay, state, optionsAll, options, setSelected]);

  const { data: nameOfAuthorDrop = [] } = useQuery(
    ["nameOfAut2345678Data", distinctKey, inputDropValue, collectionName],
    getFieldDropDowns({
      collegeId,
      search: inputDropValue,
      collection: collectionName,
      key: distinctKey,
    }),
    { keepPreviousData: true }
  );

  const uploadFile = async (file: any) => {
    const storage = getStorage();
    const fileRef = ref(storage, `files/${uuid()}/${file.name}`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (collectionName && distinctKey && nameOfAuthorDrop.length > 0) {
      setFilteredOptions(nameOfAuthorDrop);
    }
  }, [collectionName, distinctKey, nameOfAuthorDrop]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    const elem = document.getElementById("dropDown" + returnKey);
    if (elem) {
      elem.addEventListener("focus", () => {
        setIsDropdownVisible(true);
      });
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      elem?.removeEventListener("focus", () => {
        setIsDropdownVisible(true);
      });
    };
  }, [dropdownRef]);

  const handleInputChange = (e: any) => {
    if (isArray) {
      if (arrayName && indexVal !== undefined && returnKey && setState) {
        if (state[arrayName] && state[arrayName][indexVal] && state[arrayName][indexVal][returnKey]) {
          const updatedState = { ...state };
          updatedState[arrayName][indexVal][returnKey] = e.target.value;
          setState(updatedState);
          setSelected(e.target.value);
          setIsDropdownVisible(true);
        } else {
          const updatedState = { ...state };
          if (!updatedState[arrayName]) {
            updatedState[arrayName] = [];
          }
          while (updatedState[arrayName].length <= indexVal) {
            updatedState[arrayName].push({});
          }
          if (typeof updatedState[arrayName][indexVal] !== "object" || updatedState[arrayName][indexVal] === null) {
            updatedState[arrayName][indexVal] = {};
          }
          updatedState[arrayName][indexVal][returnKey] = e.target.value;
          setState(updatedState);
          setSelected(e.target.value);
          setIsDropdownVisible(true);
        }
      }
    } else {
      const value: string = e.target.value;
      setSelected(e.target.value);
      // setFilteredOptions(
      //   options?.filter((x: any) => {
      //     return optionDisplay ? x?.[optionDisplay]?.toLowerCase()?.includes(value.toLowerCase()) : x?.toLowerCase()?.includes(value.toLowerCase());
      //   })
      // );

      if (setState) {
        if (returnKey) {
          setState({ ...state, [returnKey]: e.target.value });
        } else {
          setState(e.target.value);
        }
      }

      if (onChange) {
        onChange({ value: e?.target?.value });
      }
      setIsDropdownVisible(true);
    }
  };

  const handleSelect = (value: any) => {
    const displayValue = optionDisplay ? value[optionDisplay] : value;
    setSelected(displayValue);
    setSearchText(""); // Also set searchText to show the selected value

    if (isArray) {
      if (arrayName && indexVal !== undefined && returnKey && setState) {
        if (state[arrayName] && state[arrayName][indexVal] && state[arrayName][indexVal][returnKey]) {
          const updatedState = { ...state };
          updatedState[arrayName][indexVal][returnKey] = optionKey ? value[optionKey] : value;
          setState(updatedState);
          setSelected(optionKey ? value[optionKey] : value);
          setIsDropdownVisible(false);
        } else {
          const updatedState = { ...state };
          if (!updatedState[arrayName]) {
            updatedState[arrayName] = [];
          }
          while (updatedState[arrayName].length <= indexVal) {
            updatedState[arrayName].push({});
          }
          if (typeof updatedState[arrayName][indexVal] !== "object" || updatedState[arrayName][indexVal] === null) {
            updatedState[arrayName][indexVal] = {};
          }
          updatedState[arrayName][indexVal][returnKey] = optionKey ? value[optionKey] : value;
          setState(updatedState);
          setSelected(optionKey ? value[optionKey] : value);
        }
      }
    } else {
      if (setState) {
        if (returnKey) {
          setState({ ...state, [returnKey]: optionKey ? value[optionKey] : value });
        } else {
          setState(optionKey ? value[optionKey] : value);
        }
      }
      if (onChange) {
        if (optionDisplay) {
          onChange({ value: optionKey ? value[optionKey] : value, name: value[optionDisplay] });
        } else {
          onChange({ value: optionKey ? value[optionKey] : value });
        }
      }
      setSelected(optionDisplay ? value[optionDisplay] : value);
    }
    setIsDropdownVisible(false); // Hide dropdown after selection
  };

  const handleClearSelectedOption = () => {
    setInputDropValue("");
    setSelected("");
    setInputValue("");
    setFilteredOptions(options);

    if (setState) {
      if (isArray && arrayName && indexVal !== undefined && returnKey) {
        const updatedState = { ...state };
        if (updatedState[arrayName] && updatedState[arrayName][indexVal]) {
          updatedState[arrayName][indexVal][returnKey] = "";
        }
        setState(updatedState);
      } else {
        if (returnKey) {
          setState({ ...state, [returnKey]: "" });
        } else {
          setState("");
        }
      }
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setInputDropValue(value);
    setFilteredOptions(
      options?.filter((x) => {
        return optionDisplay ? x[optionDisplay]?.toLowerCase().includes(value?.toLowerCase()) : x?.toLowerCase().includes(value?.toLowerCase());
      })
    );

    if (!typable && value === "") {
      handleClearSelectedOption();
    }
  };

  useEffect(() => {
    let newValue;
    if (type !== "file") {
      if (isArray) {
        newValue = selected;
      } else if (returnKey) {
        newValue = valueShowKey ? state?.[returnKey]?.[valueShowKey] : state?.[returnKey];
      } else {
        newValue = state;
      }
    } else {
      newValue = undefined;
    }

    setInputValue(newValue);
  }, [state, type, isArray, selected, returnKey, valueShowKey]);

  useEffect(() => {
    let newValue;
    if (!didntShowKey) {
      if (isArray) {
        newValue = selected;
      } else if (returnKey) {
        newValue = valueShowKey ? state?.[returnKey]?.[valueShowKey] : state?.[returnKey];
      } else {
        newValue = state;
      }
    } else {
      if (selected) {
        newValue = selected;
      } else if (returnKey) {
        newValue = valueShowKey ? state?.[returnKey]?.[valueShowKey] : state?.[returnKey];
      } else {
        newValue = state;
      }
    }
    setInputValue(newValue);

    
    if (typable) {
      newValue = state;
    }
    setInputDropValue(newValue);
  }, [state, isArray, selected, returnKey, valueShowKey, didntShowKey]);

  useEffect(() => {
    if (isArray) {
    } else {
      if (returnKey) {
        if (!state && !state?.[returnKey]) {
          setInputValue("");
          setInputDropValue("");
          setSelected("");
        }
      } else {
        if (!state) {
          setInputValue("");
          setInputDropValue("");
          setSelected("");
        }
      }
    }
  }, [state, setSelected, returnKey]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const handleWheel = (e: WheelEvent) => {
        if (document.activeElement === input) {
          e.preventDefault();
        }
      };
      input.addEventListener("wheel", handleWheel);
      return () => {
        input.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const mainInputRef = useRef<HTMLInputElement | null>(null);

  const handleMainInputFocus = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    setIsDropdownVisible(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && mainInputRef.current !== event.target) {
        setIsDropdownVisible(false);
      }
    };
    const elem = document.getElementById("dropDown" + returnKey);
    if (elem) {
      elem.addEventListener("focus", () => {
        setIsDropdownVisible(true);
      });
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      elem?.removeEventListener("focus", () => {
        setIsDropdownVisible(true);
      });
    };
  }, [dropdownRef, mainInputRef]);

  const [searchText, setSearchText] = useState("");

  return (
    <>
      {type === "checkbox" ? (
        <div className=" bg-slate-50 rounded-md ml-2">
          <label className={`customInput ${className} flex `} style={style}>
            <input
              required={required}
              disabled={disabled}
              checked={(returnKey && state[returnKey]) ?? false}
              className="h-8 w-8 m-2"
              onChange={() => {
                if (setState && returnKey) {
                  setState({ ...state, [returnKey]: !state[returnKey] });
                } else if (setState) {
                  setState(!state);
                }
              }}
              type={type}
            />
            <span className="input__label ml-5">{fieldName}</span>
          </label>
        </div>
      ) : (
        <>
          {type !== "drop" ? (
            <label className={`customInput ${className}`} style={style}>
              <input
                value={inputValue || ""}
                ref={inputRef}
                required={required}
                maxLength={maxLength}
                min={min}
                max={max}
                disabled={disabled}
                className="input__field outline-none border border-gray-200 rounded-md lg:text-sm xl:text-md lg:text-left md:text-center"
                onChange={async (e) => {
                  if (isArray) {
                    if (arrayName && indexVal !== undefined && returnKey && setState) {
                      if (state[arrayName] && state[arrayName][indexVal] && state[arrayName][indexVal][returnKey]) {
                        const updatedState = { ...state };
                        updatedState[arrayName][indexVal][returnKey] = e.target.value;
                        setState(updatedState);
                        setSelected(e.target.value);
                      } else {
                        const updatedState = { ...state };
                        if (!updatedState[arrayName]) {
                          updatedState[arrayName] = [];
                        }
                        while (updatedState[arrayName].length <= indexVal) {
                          updatedState[arrayName].push({});
                        }
                        if (typeof updatedState[arrayName][indexVal] !== "object" || updatedState[arrayName][indexVal] === null) {
                          updatedState[arrayName][indexVal] = {};
                        }
                        updatedState[arrayName][indexVal][returnKey] = e.target.value;
                        setState(updatedState);
                        setSelected(e.target.value);
                      }
                    }
                  } else {
                    if (onChange) {
                      onChange({ value: e.target.value });
                    }
                    if (type === "file") {
                      const url = await uploadFile(e?.target?.files?.[0]);
                      if (setState) {
                        if (returnKey) {
                          setState({ ...state, [returnKey]: url + "" });
                        } else {
                          setState(url + "");
                        }
                      }
                    } else {
                      if (setState) {
                        if (returnKey) {
                          setState({ ...state, [returnKey]: e.target.value });
                        } else {
                          setState(e.target.value);
                        }
                      }
                    }
                  }
                }}
                type={type}
                placeholder=" "
              />
              <span className="input__label">
                {fieldName.replace(/\*$/, "")}
                {fieldName.endsWith("*") && <span className="mandatory-asterisk">*</span>}
              </span>
            </label>
          ) : (
            <div style={{ position: "relative" }}>
              <div ref={dropdownRef}>
                <label className={`customInput ${className}`} style={style}>
                  <input
                    id={"dropDown" + returnKey}
                    ref={mainInputRef} // Add this line
                    disabled={disabled}
                    required={required}
                    maxLength={maxLength}
                    min={min}
                    max={max}
                    value={inputValue || ""}
                    className="input__field"
                    type={type}
                    placeholder=" "
                    readOnly={!typable}
                    onFocus={handleMainInputFocus}
                    onChange={handleInputChange}
                  />

                  <span className="input__label">
                    {fieldName.replace(/\*$/, "")}
                    {fieldName.endsWith("*") && <span className="mandatory-asterisk">*</span>}
                  </span>
                  <p className="absolute ptagfordrop h-3 right-0 w-3 z-[100]">v</p>
                </label>
                {isDropdownVisible && (
                  <div style={{ position: "absolute", zIndex: 110, width: "100%" }}>
                    {!typable && (
                      <>
                        <input id={`searchInput${returnKey}`} ref={searchInputRef} placeholder="Search...." type="search" value={inputDropValue} className="w-full max-w-md px-2 py-2 text-lg border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 h-[30px] ms-1 bg-slate-100 mt-[-12px]" onChange={handleSearchInputChange} />
                      </>
                    )}
                    <ul id="scrollBarEnable" className=" z-[110] ms-1 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60  overflow-auto" style={{ overflowY: "auto", maxHeight: "240px", ...style }}>
                      {filteredOptions?.map((option, index) => (
                        <li key={index} onClick={() => handleSelect(option)} className="p-2 cursor-pointer hover:bg-gray-100" style={{ ...style }}>
                          {optionDisplay ? option[optionDisplay] : option} {aditionalInfo ? "[" + option[aditionalInfo] + "]" : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Input;
