import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getProfile } from '../../queryHooks';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { getAllMinor, getDropdownData } from '../../pages/college/ApiServices';
import Input from '../../NecttosComp/Input/Input';
import { SectionHeader, plustwo } from '../Form/Component';
import 'react-circular-progressbar/dist/styles.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import DropableComponents from '../Form/DropableComponents';
import Button from '../../NecttosComp/Button/Button';
import moment from 'moment';

interface ApplicationOpenProps {
	handleLogout?: () => void;
	type?: any;
	userId?: string;
	viewMode?: boolean;
	managedBy?: string;
	paramsRecieved?: any;
}

const FYUGPM: React.FC<ApplicationOpenProps> = ({ viewMode, userId }) => {
	const { collegeId: contextCollegeId } = useContext(FirebaseContext);
	const [collegeId, setCollegeId] = useState(contextCollegeId);
	const [dropDowns, setDropDowns] = useState<any[]>([]);
	const [values, setValues] = useState<any>({});
	const [showModal, setShowModal] = useState(false);
	const [message, setMessage] = useState('');
	const [modalVisible, setModalVisible] = useState<{ [key: string]: boolean }>({});
	const [confirmationState, setConfirmationState] = useState<{ [key: string]: string }>({});

	useEffect(() => {
		const storedCollegeId = localStorage.getItem('collegeId');
		if (!collegeId && storedCollegeId) {
			setCollegeId(storedCollegeId);
		} else if (!collegeId && !storedCollegeId) {
			localStorage.clear();
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [collegeId]);

	const { data } = useQuery('profileData', getProfile);

	useEffect(() => {
		const fetchDropdowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		fetchDropdowns();
	}, []);

	useEffect(() => {
		if (data) {
			const classRole = data.collegeRoles?.find((x: any) => x.type === 'student');
			const classId = classRole?.classId || null;
			setValues((prevValues: any) => ({
				...prevValues,
				classId,
				...data,
			}));
		}
	}, [data]);

	const saveData = useCallback(async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/profile/autoUpdateProfile', values);

			if (resp.data.message === 'Profile successfully updated') {
				setShowModal(true);
				setMessage(moment().format('hh:mm:ss A'));
				return resp.data;
			}
		} catch (error) {
			console.error('Error saving data:', error);
		}
	}, [values]);

	const { data: minorData = [] } = useQuery(['getAttllMinor', values?._id, values?.classId, collegeId], getAllMinor(collegeId, values?._id, values?.classId));

	const DSC_B = useMemo(() => minorData.filter((x: any) => x.type === 'DSC_B' && x.semester === 'Semester 1'), [minorData]);
	const DSC_C = useMemo(() => minorData.filter((x: any) => x.type === 'DSC_C' && x.semester === 'Semester 1'), [minorData]);
	const MDC = useMemo(() => minorData.filter((x: any) => x.type === 'MDC' && x.semester === 'Semester 1'), [minorData]);

	const filteredSubjects = useMemo(() => {
		const subjects = DSC_B.find((x: any) => x._id === values.dsc_b_1);
		const subjects2 = DSC_C.find((x: any) => x._id === values.dsc_c_1);
		return [values.subject3Name, values.subject4Name, values.subject5Name, values.subject6Name, values.subject7Name, subjects?.blockSubject, subjects2?.blockSubject].filter(Boolean).map((subject) => subject.toLowerCase().replace(/\s+/g, ''));
	}, [values, DSC_B, DSC_C]);

	const filterMDC = useMemo(() => {
		return MDC.filter((x: any) => {
			const blockSubject = x?.blockSubject?.toLowerCase().replace(/\s+/g, '');
			return !filteredSubjects.includes(blockSubject);
		});
	}, [MDC, filteredSubjects]);

	const handleLogout = () => {
		localStorage.clear();
		window.location.reload();
	};

	const onSaveRollNumber = async (type: string) => {
		try {
			const postData = { collegeId, userId: values._id, type };
			const instance = await getAxiosTokenInstance();
			const { data } = await instance.post('/college/fyugp/conformingAllotment', postData);
			if (data.statusCode !== 200) {
				throw new Error(data.message);
			}
			setModalVisible((prevState) => ({ ...prevState, [type]: false }));
			alert(`You have successfully confirmed your ${type} course.`);
		} catch (error) {
			alert('API Error');
		}
	};

	const onUnfinalyseCourse = async (type: string) => {
		try {
			const postData = { collegeId, userId: values._id, type };
			const instance = await getAxiosTokenInstance();
			const { data } = await instance.post('/college/fyugp/unfinalyseAllotment', postData);
			if (data.statusCode !== 200) {
				throw new Error(data.message);
			}
			setModalVisible((prevState) => ({ ...prevState, [type]: false }));
			alert(`You have successfully unfinalysed your ${type} course.`);
		} catch (error) {
			alert('API Error');
		}
	};

	const handleConfirmClick = (type: any, action: 'finalize' | 'unfinalize') => {
		const currentState = confirmationState[type] || 'initial';

		if (currentState === 'initial') {
			setConfirmationState((prevState) => ({ ...prevState, [type]: 'confirming' }));
		} else if (currentState === 'confirming') {
			setConfirmationState((prevState) => ({ ...prevState, [type]: 'finalConfirm' }));
		} else {
			action === 'finalize' ? onSaveRollNumber(type) : onUnfinalyseCourse(type);
			setConfirmationState((prevState) => ({ ...prevState, [type]: 'initial' }));
		}
	};

	const handleCancelClick = (type: any) => {
		setConfirmationState((prevState) => ({ ...prevState, [type]: 'initial' }));
	};

	const renderConfirmationButtons = (type: any, action: 'finalize' | 'unfinalize') => {
		const currentState = confirmationState[type] || 'initial';

		if (currentState === 'confirming') {
			return (
				<div className='flex flex-col'>
					<p className='text-yellow-600 text-lg mb-4'>
						Are you sure you want to {action} this {type}?
					</p>
					<div className='flex space-x-4'>
						<button className='bg-green-600 text-white py-2 px-6 rounded-full' onClick={() => handleConfirmClick(type, action)}>
							Yes, I'm sure
						</button>
						<button className='bg-red-600 text-white py-2 px-6 rounded-full' onClick={() => handleCancelClick(type)}>
							Cancel
						</button>
					</div>
				</div>
			);
		}

		if (currentState === 'finalConfirm') {
			return (
				<div className='flex flex-col'>
					<p className='text-red-600 text-lg mb-4'>This is your last chance to {action}. Are you absolutely sure?</p>
					<div className='flex space-x-4'>
						<button className='bg-green-600 text-white py-2 px-6 rounded-full' onClick={() => handleConfirmClick(type, action)}>
							Yes, {action} now
						</button>
						<button className='bg-red-600 text-white py-2 px-6 rounded-full' onClick={() => handleCancelClick(type)}>
							Cancel
						</button>
					</div>
				</div>
			);
		}

		return (
			<button className={`bg-${action === 'finalize' ? 'blue' : 'red'}-600 text-white py-4 px-8 rounded-full shadow-md hover:bg-${action === 'finalize' ? 'blue' : 'red'}-700 hover:shadow-lg transition-all duration-200`} onClick={() => handleConfirmClick(type, action)}>
				{action === 'finalize' ? `Confirm Your ${type}` : `Unfinalyse Your ${type}`}
			</button>
		);
	};

	const renderAllotment = (allotment: any, type: any) => {
		return (
			<div
				key={type}
				hidden={!allotment.priority}
				className='p-8 border border-gray-300 rounded-2xl shadow-lg mb-8 bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out'
				style={{
					background: 'linear-gradient(135deg, #f9f9f9, #f1f1f1)',
					color: '#2c3e50',
				}}>
				<h3 className='text-3xl font-bold mb-4 text-gray-800'>{type || 'Untitled Allotment'}</h3>

				<div className='flex items-center mb-6'>
					<div className='flex items-center justify-center mr-6 p-4 bg-blue-100 rounded-full shadow-sm'>
						<p className='text-4xl font-extrabold text-blue-700'>{allotment.priority?.toString().padStart(2, '0') || '--'}</p>
					</div>
					<div>
						<p className='text-xl font-semibold text-gray-900'>{allotment.subjectName?.toUpperCase() || 'Wait for Allotement'}</p>
						<p className='text-lg text-gray-700'>{allotment.departmentName || 'Wait for Allotement'}</p>
						<p className='text-lg text-gray-700'>{allotment.quotaType || 'Wait for Allotement'}</p>
					</div>
				</div>

				{allotment?.seatConformed ? (
					<div className='flex flex-col p-6 bg-white rounded-lg shadow-lg'>
						<p className='text-green-700 text-lg font-semibold mb-4'>
							This {type} has been successfully confirmed on {moment(new Date(allotment?.approvedOn)).format('DD-MM-YYYY hh:mm A')}
						</p>
						{allotment.waLink ? (
							<a href={allotment.waLink} target='_blank' rel='noopener noreferrer' className='inline-block bg-green-500 text-white text-center font-semibold py-3 px-6 rounded-md hover:bg-green-600 transition-transform transform hover:-translate-y-1 shadow-md mb-4'>
								Join WhatsApp Group
							</a>
						) : (
							<button disabled className='inline-block bg-gray-400 text-white text-center font-semibold py-3 px-6 rounded-md cursor-not-allowed mb-4'>
								WhatsApp link not available. Please ask your teacher to add the link here.
							</button>
						)}
						{renderConfirmationButtons(type, 'unfinalize')}
					</div>
				) : (
					renderConfirmationButtons(type, 'finalize')
				)}
			</div>
		);
	};

	const [secondLanguageArray, setSecondLanguageArray] = useState([]);

	useEffect(() => {
		const language = dropDowns?.[11]?.[collegeId] || dropDowns?.[11]?.secondLanguage;
		setSecondLanguageArray(language);
	}, [dropDowns, collegeId]);

	useEffect(() => {
		if (!collegeId) {
			console.log({ collegeId });
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [collegeId]);

	const allotments = useMemo(
		() => [
			{ ...minorData.find((x: any) => x.type === 'DSC_B_A'), type: 'DSC_B' },
			{ ...minorData.find((x: any) => x.type === 'DSC_C_A'), type: 'DSC_C' },
			{ ...minorData.find((x: any) => x.type === 'MDC_A'), type: 'MDC' },
		],
		[minorData],
	);



	return (
		<div className='flex flex-col items-center lg:h-full'>
			<header className='bg-[#013E7F] shadow-lg sticky top-0 z-50 w-full py-4 flex justify-between items-center px-4'>
				<div className='flex items-center space-x-4'>
					{values?.photoURL && <img src={values.photoURL} alt='Profile' className='w-10 h-10 rounded' />}
					<div className='text-white text-xl font-semibold'>NECTTOS FYUGP - STUDENT PORTAL</div>
				</div>
				<Button type='close' onClick={handleLogout}>
					Logout
				</Button>
			</header>

			<main className='flex justify-center w-full'>
				<div className='flex flex-col w-full max-w-6xl h-auto bg-white shadow-xl rounded-lg p-1'>
					<div className='w-full p-1 bg-gray-50 rounded-t-lg'>
						<div className='flex items-center space-x-4 mb-6'>
							{values?.photoURL && <img src={values.photoURL} alt='Profile' className='w-24 h-30 rounded-full' />}
							<div>
								<h2 className='text-lg font-semibold'>Name: {values?.name}</h2>
								<h2 className='text-lg font-semibold'>Major: {values?.shortName}</h2>
								{values?.index && <h2 className='text-lg font-semibold'>Index: {values.index}</h2>}
								{values?.rcMark && <h2 className='text-lg font-semibold'>Standardised Index Mark: {values.rcMark}</h2>}
							</div>
						</div>
						<div className='space-y-6'>
							<SectionHeader key='59' title={'Plus Two Subjects'} />
							<div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4'>
								{plustwo?.map((terms: any) => (
									<Input key={terms.fieldName} required={terms.required} disabled={viewMode} width='100%' fieldName={`${terms.fieldName} Name`} returnKey={`${terms.returnKey}Name`} state={values} setState={setValues} options={terms.options} type='drop' />
								))}
							</div>
							<div className='p-8'>
								<div className='mb-8 p-6 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 shadow-md rounded-lg'>
									<h2 className='text-xl font-bold mb-2'>Important Information</h2>
									<p className='text-lg'>
										You have been allotted the following courses. If you confirm your allotment, please press the confirm button. <strong>Note:</strong> Once confirmed, you cannot make changes to your allotment.
									</p>
								</div>
							</div>

							{allotments.map((allotment) => renderAllotment(allotment, allotment.type))}

							<SectionHeader key='d19' title={'AEC OL (SECOND LANGUAGE)'} />
							<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
								<Input disabled={viewMode} fieldName='AEC OL (Second Language)' setState={setValues} state={values} returnKey='secondLanguage' options={secondLanguageArray} type='drop' width='100%' />
							</div>

							<SectionHeader key='d18' title={'Minor 1 (DSC_B)'} />
							<DropableComponents array={DSC_B} setValues={setValues} retunPreFix={'dsc_b_'} values={values} />
							<SectionHeader key='d59' title={'Minor 2 (DSC_C)'} />
							<DropableComponents array={DSC_C} setValues={setValues} retunPreFix={'dsc_c_'} values={values} />
							<SectionHeader key='d20' title={'MDC (MDC)'} />
							<DropableComponents array={filterMDC} setValues={setValues} retunPreFix={'mdc_'} values={values} />
						</div>
					</div>
					<div className='flex justify-end space-x-4 mt-4'>
						<Button width='130px' height='80px' type={showModal ? 'save' : 'fetch'} onClick={() => saveData()}>
							{showModal ? `Successfully Saved @ ${message}` : 'Save Selected Items'}
						</Button>
					</div>
				</div>
			</main>
			<footer className='bg-[#013E7F] w-full py-4 mt-4'>
				<div className='text-white text-center'>&copy; 2024 FYUGP. All Rights Reserved.</div>
			</footer>
		</div>
	);
};

export default FYUGPM;
